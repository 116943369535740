import axios from "axios";

axios.defaults.baseURL = 'https://gr.mrz.omo.web.red1.us:8000/api/';
// axios.defaults.headers.common['Authorization'] = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhZG1pbiIsImFjY2VzcyI6InN1ZG8iLCJleHAiOjE2ODk1MDQwOTh9.iTqOYR5fDY26ax1jDdFXzwYjd8r7iV0SERiDZXLRyks'

export async function get(url, config = {}) {
    try {
        const response = await axios.get(url, config);
        return response.data;
    } catch (error) {
        return error.response.status;
    }
}

export async function post(url, config) {
    try {
        const response = await axios.post(url, config);
        
        return response.data;
    } catch (error) {
        console.log(config);
        return console.log(error);
    }
}