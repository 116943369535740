import { post, get } from './Service/HttpClient';
import axios from "axios";
import qs from 'qs'
import { useState, useEffect } from 'react';
import farvardin from 'farvardin'
import './style/animate.css'
import './style/animsition.min.css'
import './style/bootstrap.min.css'
import './style/daterangepicker.css'
import './style/font-awesome.min.css'
import './style/hamburgers.min.css'
import './style/icon-font.min.css'
import './style/main.css'
import './style/select2.min.css'
import './style/util.css'
import './style/style.css'



function App() {
  const [userName, setUserName] = useState('')
  const [userData, setUserData] = useState({'expire':0})
  const [loadin, setLoadin] = useState(true)
  const [token, setToken] = useState('')

  useEffect(() => {
let data = qs.stringify({
  'username': 'admin',
  'password': 'ho3einkj40' 
});

let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: 'https://gr.mrz.omo.web.red1.us:8000/api/admin/token',
  headers: { 
    'Content-Type': 'application/x-www-form-urlencoded'
  },
  data : data
};

axios.request(config)
.then((response) => {
  setToken(response.data.access_token)
})
.catch((error) => {
  console.log(error);
});

  },[]);
  const SearchUser = (e) => { 
    setLoadin(true)
    e.preventDefault();
    get(`user/${userName}`, {headers: {'Authorization': `Bearer ${token}`}}).then(response => setUserData(response))
    if (userData === 404) {
      setUserData({'expire':0})
      setLoadin(true)
      console.log('loadin')

    } else if (userData == {} || userData == {'expire': 0}) {
      setLoadin(true)
      setUserData({'expire':0})
      console.log('loadin')
    } else {  
      setLoadin(false)
      setUserData({'expire':0})
      console.log('false')
    }
    setUserName('');
    
  }
  if(userData.expire) {
    var a = new Date(userData.expire * 1000);
    var months = ['1','2','3','4','5','6','7','8','9','10','11','12'];
    var year = parseInt(a.getFullYear());
    var month = parseInt(months[a.getMonth()]);
    var date = parseInt(a.getDate());
    var hour = a.getHours();
    var min = a.getMinutes();

    var time =  farvardin.gregorianToSolar(year , month , date , "string") + ' | ' + hour + ":" + min ;
  }
      

  var test2 = userData.used_traffic / 1073741824;
  var test3 = test2.toFixed(2);
  return (
    <div className="limiter">
        <div className="container-login100">
          <div className="wrap-login100 p-b-160 p-t-50">
            <form className="login100-form validate-form" onSubmit={SearchUser}>
              <span className="login100-form-title p-b-43">
              بررسی وضعیت VPN
              </span>
              <div className="wrap-input100 rs1 validate-input" data-validate="Username is required">
              <input value={userName} onChange={(e) => setUserName(e.target.value)} className="input100" type="text" name="username" />
              <span className="label-input100">نام کاربری</span>
              </div>
              <div className="container-login100-form-btn">
              <button className="login100-form-btn" name='submit'>
              مشاهده
              </button>
              </div>
              
              <div className='table-detaile'>
                <ol>
                  <li className='detailed'>نام کاربری: {userData.username}</li>
                  <li className='detailed'>تاریخ اتمام سرویس :{userData.expire == null ? 'تا زنده ای' : time}</li>
                  <li className='detailed'>وضعیت: {userData.status === "active" ? '✅فعال' : '❌غیرفعال'}</li>
                  <li className='detailed'>حجم کل: {userData.data_limit == null ? 'نامحدود' : userData.data_limit / 1073741824 + "گیگابایت" }</li>
                  <li className='detailed'>حجم مصرف شده : {test3 } گیگابایت</li>
                </ol>
              </div>
              <div className="text-center w-full p-t-23">

              </div>
            </form>
          </div>
        </div>
    </div>
  );
}

export default App;
